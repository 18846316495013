<template>
  <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4 class="text-center">
            <h1 class="display-3 font-weight-bold mb-3 text-grey">
              BACT
            </h1>
            <v-card class="elevation-12">
              <v-toolbar dark :color="primaryColor">
                <v-toolbar-title>{{ $route.name === 'admin login' ? 'تسجيل دخول مدير النظام' : 'تسجيل دخول اللجنة الأكاديمية' }}</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field
                    v-model="email"
                    :error-messages="emailErrors"
                    :prepend-icon="mdiEmail"
                    name="email"
                    :label="$t('form.email')"
                    type="text"
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    :error-messages="passwordErrors"
                    :prepend-icon="mdiLock"
                    name="password"
                    :label="$t('form.password')"
                    type="password"
                    @input="$v.password.$touch()"
                    @blur="$v.password.$touch()"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn @click="submit" class="white--text" :color="primaryColor">{{ $t('form.login') }}</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
  </v-main>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { mdiEmail, mdiLock } from '@mdi/js';
import { mapActions } from 'vuex';

export default {
  mixins: [validationMixin],

  validations: {
    email: { required, email },
    password: { required },
  },

  data: () => ({
    email: '',
    password: '',
    mdiEmail,
    mdiLock
  }),

  computed: {
    emailErrors () {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push('Must be valid e-mail');
      !this.$v.email.required && errors.push('E-mail is required');
      return errors;
    },
    passwordErrors () {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push('Password is required');
      return errors;
    },
  },

  methods: {
    ...mapActions('AuthAdmin', ['login']),
    submit () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.login({
          email: this.email,
          password: this.password,
          type: 'admin'
        }).then(() => {
          this.$router.push('/dashboard/main');
        }).catch(err => {
          console.log(err);
        })
      }
    },
    clear () {
      this.$v.$reset();
      this.email = '';
      this.password = '';
    },
  },
}
</script>

<style scoped>

</style>
